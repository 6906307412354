window.edinburghfestivals ||= {}

edinburghfestivals.menu =
  setup: ->
    @add_listeners()

  add_listeners: ->

    $('.menu_toggle').on 'click', (e) ->
      e.preventDefault()
      $(this).toggleClass('active')

      tl = gsap.timeline();
      if $(this).hasClass('active')
        tl.to(".top_bun", {duration: 0.1, y: 4}, 0);
        tl.to(".bottom_bun", {duration: 0.1, y: -4}, 0);
        tl.to(".cheese", {duration: 0.1, opacity: 0}, 0.1);
        tl.to(".hamburger", {duration: 0.1, rotate: 225}, 0.2);
        tl.to(".bottom_bun", {duration: 0.1, rotate: -90}, 0.2);

      else
        tl.to(".bottom_bun", {duration: 0.1, rotate: 0}, 0);
        tl.to(".hamburger", {duration: 0.1, rotate: 0}, 0);
        tl.to(".cheese", {duration: 0.1, opacity: 1}, 0.1);
        tl.to(".top_bun", {duration: 0.1, y: -6}, 0.2);
        tl.to(".bottom_bun", {duration: 0.1, y: 6}, 0.2);
      
      $('body').toggleClass('menu_open')
      $(this).blur()

    $('.section_toggle').on 'click', (e) ->
      e.preventDefault()
      $(this).toggleClass('active')
      $(this).siblings().toggleClass('open')
      $(this).closest('li').toggleClass('open')
      $(this).blur()
