window.edinburghfestivals ||= {}

edinburghfestivals.overview =
  setup: ->
    @setup_children()

  setup_children: ->
    return false unless document.querySelector(".overview_child")

    overview_children = document.querySelectorAll(".overview_child")

    overview_children.forEach (child) ->
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: child,
          start: "top 50%"
        }
      })
      
      tl.fromTo(child,{ y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.7})