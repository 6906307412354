window.edinburghfestivals ||= {}

edinburghfestivals.direction_page =
  setup: ->
    @setup_directions()

  setup_directions: ->
    travel_types = document.querySelectorAll(".direction_page_travel_type")
    travel_type_links = document.querySelectorAll(".direction_page_travel_types li")
    
    return false unless travel_types


    travel_types.forEach (travel_type, idx) ->
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: travel_type,
          start: "-1px top",
          end: "bottom #{document.querySelector('.direction_page_travel_types ul').offsetHeight + 1}px"
          onEnter: => 
            travel_type.classList.add('active')
            travel_type_links[idx].classList.add('active')
          onLeave: => 
            travel_type.classList.remove('active')
            travel_type_links[idx].classList.remove('active')
          onEnterBack: => 
            travel_type.classList.add('active')
            travel_type_links[idx].classList.add('active')
          onLeaveBack: => 
            travel_type.classList.remove('active')
            travel_type_links[idx].classList.remove('active')
        }
      })