class Gallery
  current_item: 0
  total_items: null
  next_link: null
  prev_link: null
  items: null
  items_with_clones: null
  thumbnails: null
  credits: null
  count: null
  prev_items: null
  next_items: null

  constructor: (@wrapper) ->
    @items = @wrapper.find('.gallery_block_item')
    @thumbnails = @wrapper.find('.gallery_block_thumbnail')
    @credits = @wrapper.find('.gallery_block_credit')
    @list = @wrapper.find('.gallery_block_list')
    @total_items = @items.length
    @next_link = @wrapper.find('.gallery_block_next_link')
    @prev_link = @wrapper.find('.gallery_block_prev_link')
    @count = @wrapper.find('.current_item')

    @clone_originals()
    @add_listeners()


  add_listeners: ->

    fired = false
    @next_link.on 'click', (e) =>
      e.preventDefault()
      if !fired
        fired = true
        @change_slide(@current_item + 1)
        setTimeout (->
          fired = false
        ), 400

    @prev_link.on 'click', (e) =>
      e.preventDefault()
      if !fired
        fired = true
        @change_slide(@current_item - 1)
        setTimeout (->
          fired = false
        ), 400

    that = @
    @thumbnails.on 'click', (e) ->
      e.preventDefault()
      that.change_slide($(this).data('thumbnail'))

    $(document).on 'keydown', (e) =>
      if !fired
        fired = true
        switch e.keyCode
          when 37
            @change_slide(@current_item - 1)
            setTimeout (->
              fired = false
            ), 400
          when 39
            @change_slide(@current_item + 1)
            setTimeout (->
              fired = false
            ), 400

    @list.on 'touchstart', (e) =>
      x_start = e.originalEvent.changedTouches[0].pageX

      @list[0].addEventListener 'touchmove', ((e) ->
        e.preventDefault()
      ), passive: false

      @list.on 'touchend', (e) =>
        x_end = e.originalEvent.changedTouches[0].pageX

        if ((x_start - x_end) > 50)
          @change_slide(@current_item + 1)
        else if ((x_end - x_start) > 50)
          @change_slide(@current_item - 1)

        @list[0].removeEventListener 'touchmove', ((e) ->
          e.preventDefault()
        ), passive: false
        @list.off 'touchend'

  change_slide: (index) ->
    @items.removeClass('current')
    @items_with_clones.removeClass('current')

    if index < 0
      @items_with_clones.css({'transition-duration': '400ms', 'transform': "translateX(#{(index * -100) - 200}%)"})
      index = @total_items - 1
      that1 = @
      that1.items_with_clones.eq(1).addClass('current')
      that1.items.eq(index).addClass('current')
      setTimeout (->
        that1.items_with_clones.css({'transition-duration': '0ms', 'transform': "translateX(#{(index * -100) - 200}%)"})
      ), 400

    else if index >= @total_items
      @items_with_clones.css({'transition-duration': '400ms', 'transform': "translateX(#{(index * -100) - 200}%)"})
      index = 0
      that2 = @
      that2.items_with_clones.eq(-2).addClass('current')
      that2.items.eq(index).addClass('current')
      setTimeout (->
        that2.items_with_clones.css({'transition-duration': '0ms', 'transform': "translateX(#{(index * -100) - 200}%)"})
      ), 400

    else
      @items.eq(index).addClass('current')
      @items_with_clones.css({'transition-duration': '400ms', 'transform': "translateX(#{(index * -100) - 200}%)"})

    @thumbnails.removeClass('current')
    @thumbnails.eq(index).addClass('current')

    @credits.removeClass('current')
    @credits.eq(index).addClass('current')

    @current_item = index
    @update_count()


  update_count: ->
    @count.text(@current_item + 1)

  clone_originals: ->
    @prev_items = @items.slice(-2).clone().addClass('clone').removeAttr('data-item')
    @next_items = @items.slice(0, 2).clone().addClass('clone').removeAttr('data-item').removeClass('current')
    @list.append(@next_items).prepend(@prev_items)
    @items_with_clones = @wrapper.find('.gallery_block_item')
    @items_with_clones.css({'transition-duration': '0ms', 'transform': "translateX(-200%)"})


window.edinburghfestivals ||= {}

edinburghfestivals.gallery =
  setup: ->
    $('.gallery_block_content_block').each ->
      gallery = new Gallery( $(@) )
