window.tictoc ||= {}

tictoc.ui =
  setup: ->
    @setup_js_class()
    @skip_to_content_link()

  setup_js_class: ->
    $('html').removeClass('no-js').addClass('js')

  skip_to_content_link: ->
    $('.skip_to_content').on 'click', (e)=>
      e.preventDefault()
      @smooth_scroll_to( e.target.getAttribute('href') )

  smooth_scroll_to: (selector)->
    $('html, body').animate
      'scrollTop': $(selector).offset().top
    , 500, ->
      $(selector).trigger('focus')
