import './vendor/gsap.min.js'
import './vendor/ScrollTrigger.min.js'

import './tictoc/frontend.js.coffee'

import './edinburghfestivals/direction_page.js.coffee'
import './edinburghfestivals/filter.js.coffee'
import './edinburghfestivals/gallery.js.coffee'
import './edinburghfestivals/menu.js.coffee'
import './edinburghfestivals/overview.js.coffee'
import './edinburghfestivals/video.js.coffee'
import './edinburghfestivals/accordion.js.coffee'
import './edinburghfestivals/custom_select.js.coffee'
import './edinburghfestivals/cookie_notice.js.coffee'
import './tictoc/preview.js.coffee'
import './tictoc/ui.js.coffee'

$(document).ready ->
  tictoc.frontend.init()
  tictoc.preview.setup()
  tictoc.ui.setup()
  edinburghfestivals.menu.setup()
  edinburghfestivals.video.setup()
  edinburghfestivals.gallery.setup()
  edinburghfestivals.overview.setup()
  edinburghfestivals.filter.setup()
  edinburghfestivals.direction_page.setup()
  edinburghfestivals.accordion.setup()
  edinburghfestivals.custom_select.setup()
  edinburghfestivals.cookie_notice.setup()
