window.edinburghfestivals ||= {}

$video_links = $('.video_link')

edinburghfestivals.video =
  setup: ->
    return false unless $video_links.length != 0

    if !document.cookie.match(/edinburghfestivals_cookies_consent=true/)
      video_span = document.createElement('span')
      video_span.innerHTML = ' We use cookies on our website to track and enhance your user experience. For more information or to update your cookie settings, please refer to our <a href="/privacy-policy">privacy policy</a>. To view this functionality please <a href="/cookies-accepted" class="accept_cookies">accept cookies</a>.'
      $video_links.each ->
        @.parentElement.before(video_span)
    else
      youtube_api = document.createElement('script')
      youtube_api.src = '//www.youtube.com/iframe_api'
      $('body').append(youtube_api)

      vimeo_api = document.createElement('script')
      vimeo_api.src = '//player.vimeo.com/api/player.js'
      $('body').append(vimeo_api)

      setTimeout ->
        $('iframe').removeAttr('height width')
      , 500

window.onYouTubeIframeAPIReady = ->
  $video_links.each ->

    # Third party video
    if @href.match(/youtube.com/) || @href.match(/youtu.be/)
      $(this).siblings('.video_block_poster').on 'click', (e) ->
        e.preventDefault()
        $(this).hide()
        player.playVideo()

    if @href.match(/youtube.com/)
      player = new (YT.Player)(@,
        videoId: "#{@href.match(/v=([\w\-]+)/)[1]}")

    if @href.match(/youtu.be/)
      player = new (YT.Player)(@,
        videoId: "#{@href.match(/\.be\/([\w\-]*)/)[1]}")

    if @href.match(/vimeo.com/)
      $(this).siblings('.video_block_poster').on 'click', (e) ->
        e.preventDefault()
        $(this).hide()
        player.play()

      player = new Vimeo.Player(this.parentNode,
        id: @href.match(/vimeo\.com.*\/(\d+$)/)[1])

      @.remove()