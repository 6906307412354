window.edinburghfestivals ||= {}

edinburghfestivals.filter =
  setup: ->
    @add_listeners()

  add_listeners: ->
    url_params = new URLSearchParams(window.location.search)
    if url_params.has('filter[]')
      window.location.href = '#filter_results'

    $('.filter_toggle').on 'click', (e) ->
      e.preventDefault()
      $('body').toggleClass('filter_active')

    @form_listener()
    @clear_listener()

  add_form_listeners: ->
    $('#filter_wrapper .filter_toggle').on 'click', (e) ->
      e.preventDefault()
      $('body').toggleClass('filter_active')

    @form_listener()
    @clear_listener()

  form_listener: ->
    $('#tag_filters').on 'change', 'input[type=checkbox]', =>
      @ajax_form()

  clear_listener: ->
    $('.clear_filter').on 'click', (e) =>
      e.preventDefault()
      $('input[type=checkbox]', '#tag_filters').attr({checked: false})
      @ajax_form()

  ajax_form: ->
    $.get $('#tag_filters').attr('action'),
      $('#tag_filters').serialize()
      (data) ->
        $('#filter_wrapper').replaceWith(data.filter_form)
        edinburghfestivals.filter.add_form_listeners()