window.edinburghfestivals ||= {}

edinburghfestivals.accordion =
  setup: ->
    @add_listeners()

  add_listeners: ->
    window.addEventListener 'resize', ->
      document.querySelectorAll('.accordion_block_content_block').forEach (block) ->
        content = block.querySelector('.accordion_block_content')

        if content.style.maxHeight
          content.style.maxHeight = "#{content.scrollHeight}px"

    document.querySelectorAll('.accordion_block_content_block').forEach (block) ->
      links = block.querySelectorAll('a.accordion_link, .accordion_block_content > p a.accordion_link')
      content = block.querySelector('.accordion_block_content')

      links.forEach (link) ->
        link.addEventListener 'click', (e) =>

          e.preventDefault()
          block.classList.toggle('open')

          if content.style.maxHeight
            content.style.maxHeight = null
          else
            content.style.maxHeight = "#{content.scrollHeight}px"

          block.scrollIntoView behavior: 'smooth'
